import { defineStore } from "pinia";
import { Notification, NotificationStore } from "../../@types/Notification";
import { tms } from "../../helpers/date"; // timestamp for console

export const useNotification = defineStore("notification", {
  state: (): NotificationStore => ({
    notifications: [],
    timeoutIds: [],
  }),

  actions: {
    async addNotification(notif: Notification) {
      this.notifications = [...this.notifications, notif];
      // console.log(tms(), "add ntfn: " + notif.msg);
    },

    async setTimeoutId(tmout: NodeJS.Timeout) {
      this.timeoutIds.push(tmout);
    },

    removeNotification() {
      const ntfn = this.notifications.shift();
      const to = this.timeoutIds.shift();
      /* eslint-disable-next-line no-console */
      console.log(tms(), "rmv ntfn: " + ntfn?.msg + ", to: " + to);
    },

    clearNotifications() {
      this.timeoutIds.forEach((n) => {
        // console.log(">> timeoutId: " + n);
        clearTimeout(n);
      });
      this.notifications = [];
      this.timeoutIds = [];
      // console.log(tms(), "rmv all ntfns");
    },
  },
});
