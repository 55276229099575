/* eslint-disable @typescript-eslint/no-explicit-any */
import { CurrentlyPlaying } from "./CurrentlyPlaying";
import { Device } from "./Device";
import { Howl } from "howler";

export class BeatTime {
  time: number;
  gear: string;
  comment: string; // free form comment
  bsVariant: string; // or 'danger', 'warning'
  constructor(time, gear = "mellow", comment = "") {
    this.time = time;
    this.gear = gear;
    this.comment = comment; // free form comment
    this.bsVariant = "success"; // or 'danger', 'warning'
  }
}
export class JSeq {
  tags: string;
  moves: object[];
  constructor(tags = "", moves = []) {
    this.tags = tags;
    this.moves = moves;
  }
}
export interface Player {
  // Ruedamatic data:
  //  General (on startup)
  rmSongIdMapping: object;
  // Scheme (on scheme select)
  currentRmScheme: string | null;
  currentRmHowl: Howl | null;
  currentRmSchemeMoves: object | null;
  currentRmSchemeCombos: object | null;
  rmSchemeReady: boolean;
  rmFlashReady: boolean; // while playing, ready state will flash on/off with the beat: to check beat accuracy
  rmHowlerReady: boolean;
  // Song specific (on song select)
  currentRmSong: string; // Spotify ID
  forecastSong: any; // relinked spotify song id
  currentRmSongBeats: [];
  currentRmSongAveGap: number;
  currentRmSongSeq: object[];
  currentRmSongTags: string;
  rmPlayingPreset: boolean; // sealed envelope if playing preset: dynamically set to airplane if "winging it" due to preset missing.  Shows by current track in GUI
  rmSongReady: boolean;
  rmCallTimeouts: number[];
  rmHandleSeekFlag: boolean;
  // metrics
  rmSchemeReadyTime: number;
  rmSongReadyTime: number;
  rmHowlerReadyTime: number;
  // legacy data:
  devices: {
    activeDevice: Device;
    list: Device[];
  };
  thisDeviceId: string;
  currentlyPlaying: CurrentlyPlaying;
  currentItemFromSDK: Spotify.Track | null;
  currentPositionFromSDK: number;
  playerState: Spotify.PlaybackState | null;
  playerStatePrevious: Spotify.PlaybackState | null;
  OkAutoplay: boolean;
  // automated setting of move sequence controls
  autoFiller: any;
  autoCallOn: boolean;
  autosetLimit: number;
  autosetDone: boolean; // used when autoCallOn true: flag when the sequence is complete
  autosetComboWeights: number[];
  autosetCurrentCombo: object | null;
  autosetCurrentPermWeights: number[];
  autosetCurrentPermutation: object | null;
  autosetBeatIndex: number;
  autosetAllow1MissIn3: boolean;
  rmCallTimingOffs: number;
  rmCallVolume: number;
}

export type PlayerError =
  | "NO_PREV_TRACK"
  | "NO_NEXT_TRACK"
  | "NO_SPECIFIC_TRACK"
  | "ALREADY_PAUSED"
  | "NOT_PAUSED"
  | "NOT_PLAYING_LOCALLY"
  | "NOT_PLAYING_TRACK"
  | "NOT_PLAYING_CONTEXT"
  | "ENDLESS_CONTEXT"
  | "CONTEXT_DISALLOW"
  | "ALREADY_PLAYING"
  | "RATE_LIMITED"
  | "REMOTE_CONTROL_DISALLOW"
  | "DEVICE_NOT_CONTROLLABLE"
  | "VOLUME_CONTROL_DISALLOW"
  | "NO_ACTIVE_DEVICE"
  | "PREMIUM_REQUIRED"
  | "UNKNOWN";

const defaultAlbum: Spotify.Album = {
  uri: "",
  name: "",
  images: [],
};

const defaultArtist: Spotify.Artist[] = [
  {
    name: "",
    uri: "",
    url: "",
  },
];

const defaultTrack: Spotify.Track = {
  uri: "",
  uid: "",
  id: null,
  type: "track",
  media_type: "audio",
  name: "",
  is_playable: true,
  album: defaultAlbum,
  artists: defaultArtist,
  duration_ms: 0,
  track_type: "audio",
  linked_from: {
    uri: null,
    id: null,
  },
};

const defaultTrackWindow: Spotify.PlaybackTrackWindow = {
  current_track: defaultTrack,
  previous_tracks: [],
  next_tracks: [],
};

const defaultPlaybackDisallows = {
  pausing: false,
  peeking_next: false,
  peeking_prev: false,
  resuming: false,
  seeking: false,
  skipping_next: false,
  skipping_prev: false,
};

const defaultPlaybackRestrictions = {
  disallow_pausing_reasons: [],
  disallow_peeking_next_reasons: [],
  disallow_peeking_prev_reasons: [],
  disallow_resuming_reasons: [],
  disallow_seeking_reasons: [],
  disallow_skipping_next_reasons: [],
  disallow_skipping_prev_reasons: [],
};

export const defaultPlaybackState: Spotify.PlaybackState = {
  context: {
    metadata: null,
    uri: null,
  },
  loading: false,
  playback_features: { hifi_status: "NONE" },
  playback_id: "",
  playback_quality: "VERY_HIGH",
  timestamp: 0,
  disallows: defaultPlaybackDisallows,
  duration: 0,
  position: 0,
  paused: true,
  repeat_mode: 0,
  shuffle: false,
  restrictions: defaultPlaybackRestrictions,
  track_window: defaultTrackWindow,
};
