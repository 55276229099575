<template>
  <template v-for="(track, index) in trackList" :key="track">
    <!-- playerStore.currentlyPlaying.repeat_state === 'track'; -->
    <div
      class="track"
      :class="{
        active: isActive(track),
        deletable: playlistStore.playlist.owner.id === authStore.me?.id || playlistStore.playlist.collaborative,
      }"
      @click="
        playSongs(
          index,
          trackList.map((e) => e.track),
        )
      "
    >
      <!-- @click.prevent.stop="dialogStore.open({ type: 'addSong', songUri: track.track.uri })" -->
      <div class="track-icon">
        <i class="track-icon-item music icon-note" />
        <i class="track-icon-item save icon-plus"></i>
      </div>
      <div :title="rmIconTitle(track)">
        <!-- <button class="track-name">{{ track.track.name }}</button> -->
        <!-- tried using a button to defeat the Chrome MediaEngagementBypassAutoplayPolicies, but works same as div -->
        <!-- <div class="track-name">{{ track.track.name }}</div> -->
        <div class="track-name">{{ rmTrackName(track) }}</div>
        <ArtistList :artist-list="track.track.artists" feat />
      </div>
      <div class="album">
        <div v-if="isAlbum(track.track.album)" class="adder">
          <i class="adder-icon icon-album" />
        </div>
        <i
          v-else
          :class="{
            'icon-ep': isEP(track.track.album),
            'icon-single': isSingle(track.track.album),
            'icon-compilation': isCompilation(track.track.album),
          }"
        />
        <AlbumLink :album="track.track.album" no-icon />
      </div>
      <div class="date">{{ date(track.added_at) }}</div>
      <div class="duration">{{ timecode(track.track.duration_ms) }}</div>
      <!-- <div v-if="playlistStore.playlist.owner.id === authStore.me?.id || playlistStore.playlist.collaborative">
        <button class="button button--nude delete" @click.prevent.stop="deleteSong(track.track.uri)">
          <i class="icon-trash-2"></i>
        </button>
      </div> -->
    </div>
  </template>
</template>

<script lang="ts" setup>
// import { NotificationType } from "../../@types/Notification";
// import { instance } from "../../api";
// import { notification } from "../../helpers/notifications";
/* eslint-disable no-console */
import { PlaylistTrack } from "../../@types/Playlist";
import { date, timecode } from "../../helpers/date";
import { playSongs } from "../../helpers/play";
import { isAlbum, isCompilation, isEP, isSingle } from "../../helpers/useCleanAlbums";
import { useAuth } from "../../views/auth/AuthStore";
import { usePlaylist } from "../../views/playlist/PlaylistStore";
import AlbumLink from "../album/AlbumLink.vue";
import ArtistList from "../artist/ArtistList.vue";
// import { useDialog } from "../dialog/DialogStore";
import { usePlayer } from "../player/PlayerStore";
import { tms } from "../../helpers/date";
// import spotify from "../../spotify";

defineProps<{
  trackList: PlaylistTrack[];
}>();

// const dialogStore = useDialog();
const playlistStore = usePlaylist();
const playerStore = usePlayer();
const authStore = useAuth();

function isActive(track): boolean {
  const hitActive = playerStore.$state.currentRmSong === track.track.uri.substring(14);
  if (hitActive) {
    console.log(
      tms(),
      "ACTIVE song per RM: " + playerStore.$state.rmSongIdMapping[playerStore.$state.currentRmSong].file,
    );
    setTimeout(() => {
      const trackElement = document.querySelector(".track.active");
      trackElement?.scrollIntoView({ block: "center" });
    }, 200);
  } else {
    console.log(
      tms(),
      "INACTIVE song: " + track.track.name + ", seeking: " + playerStore.$state.currentRmSong.substring(0, 3),
    );
  }
  return hitActive;
}
function rmIconTitle(t: PlaylistTrack): string {
  const tag = rmTag(t);
  return !playerStore.autoCallOn && tag
    ? "✉ = using preset calls for this song [tags are brackets, if any]"
    : "✈ = calls will be chosen by AI on the fly";
}
function rmTag(t: PlaylistTrack): string {
  return playerStore.rmSongIdMapping[t.track.id]?.schemeTags["scheme_" + playerStore.currentRmScheme]?.replace(
    "_noTags",
    "~",
  );
}
function rmTrackName(t: PlaylistTrack): string {
  const tag = rmTag(t);
  const prefix = tag ? "[" + tag + "] " : "";
  const icon = !playerStore.autoCallOn && tag ? "✉" : "✈";
  return icon + prefix + "bpm:" + playerStore.rmSongIdMapping[t.track.id]?.bpm + " " + t.track.name;
}
</script>

<style lang="scss" scoped>
@use "sass:color";
@import "../../assets/scss/colors";
@import "../../assets/scss/responsive";

.track-name {
  font-weight: bold;
}

.track {
  align-items: center;
  border-radius: 0.3rem;
  cursor: pointer;
  display: grid;
  gap: 0.8rem;
  grid-template-columns: 2.2rem 1fr 0.9fr 0.3fr 2.8rem;
  margin-bottom: 0.4rem;
  padding: 0.4rem 0.8rem;

  &.deletable {
    grid-template-columns: 2.2rem 1fr 0.9fr 0.3fr 2.8rem auto;
  }

  &-icon {
    &-item {
      font-size: 1.5rem;
      opacity: 0.1;
    }

    .save {
      cursor: pointer;
      display: none;
    }

    &:hover {
      .music {
        display: none;
      }

      .save {
        display: block;
        opacity: 0.8;
      }
    }
  }

  .delete {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  .link,
  .date,
  .owner {
    color: currentcolor;
    font-size: 0.9rem;
    font-style: italic;
    opacity: 0.5;
    text-decoration: none;
  }

  .date {
    text-align: right;
  }

  .link {
    &:hover {
      color: var(--primary-color);
      opacity: 1;
    }
  }

  &:hover {
    background-color: var(--bg-color-dark);
  }

  &:active {
    background-color: var(--bg-color);
  }

  .adder {
    &-button {
      background: none;
      border: none;
      color: var(--primary-color);
      cursor: pointer;
      display: none;
      opacity: 1;
    }

    &:hover {
      .adder-button {
        display: block;
      }

      .adder-icon {
        display: none;
      }
    }
  }
}

.duration {
  font-size: 0.9rem;
  font-weight: bold;
  padding-right: 0.5rem;
  text-align: right;
}

.album {
  align-items: center;
  display: flex;
  font-size: 0.9rem;
  text-align: left;

  i {
    font-size: 1rem;
    margin-right: 0.8rem;
    opacity: 0.3;

    &.icon-album {
      color: var(--primary-color);
      opacity: 1;
    }
  }
}
</style>
